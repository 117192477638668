.container {
    padding-top:20px;   
    max-width:80vw;
    margin:0 auto;
    display:flex;
    flex-wrap: wrap;
    margin-bottom:100px;
}
.header {
    font-size: 3.5rem;
    color: blueviolet;
    width: 80vw;
    margin: 0 auto;
}
@media screen and (max-width: 850px) {
    .container {
        width:95vw;
        flex-direction: column;
        align-items:center;
    }
    .header {
        font-size: 2.5rem;
    }
}

/* Note to future self you need to figure out why the page is slightly too large on phones and causes side scrolling */