body {
    background-color:#1A1A1A;
    font-family: Tahoma,Georgia, sans-serif;
    margin-top: 10px;
}
* {
    margin:0;
    padding:0;
    font-family: 'Roboto Mono';
}
p {
    font-size: 1.1rem;
}
a {
    font-size: 1.1rem;
}
