.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    z-index:100;
    background-color: #1A1A1A;
}

.list{
    list-style-type: none;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    position: relative;
}

.items,
.contact {
    margin: 0 10px;
    color:blueviolet;
    cursor: pointer;
    text-decoration: none;
    font-weight:bolder;
    text-shadow: 0 0 .125em rgb(0, 0, 0), 0 0 .15em blueviolet;
    transition: all .2s ease-in-out;
}
.items:hover,
.contact:hover {
    font-weight:400;
    color: hsl(271, 96%, 83%);
    text-shadow: 0 0 1em blueviolet,0 0 2em blueviolet;
}
.contact:hover {
    border-color: hsl(271, 96%, 63%);
    box-shadow:0 0 .4em blueviolet;
}
.contact {   
    display:inline-block; 
    padding:5px 15px;
    border:2px solid blueviolet;
    border-radius: 10px;
    margin-right: 2vw;
    /* margin-left:1vw; */
    transition:all 0.3s ease-in-out;
    letter-spacing: 2px;
    box-shadow: 0 0 2px rgb(0, 0, 0), 0 0 .25em blueviolet;
}
.hambergerBtn {
    display: none;
    position: absolute;
    border:none;
    background-color: transparent;
    right: 10px;
    top: 7px;
}
.img {
    width: 40px;
    height: 40px;
    cursor:pointer;
}

.title {
    font-size: 1.5rem;
    position:absolute;
    left:7vw;
    top:50%;
    height:50%;
    transform:translate(0,-50%);
    cursor: pointer;
    color:blueviolet;
    text-shadow: 0 0 .125em rgb(0, 0, 0), 0 0 .3em #8a2be2;
    z-index:10;
    transition: all .2s ease-in-out;
}
.title:hover {
    text-shadow:  0 0 1em blueviolet;
}
.backdrop {
    width:100vw;
    height:100vh;
    position:absolute;
    display:none;
}
@media screen and (max-width: 700px) {
    .backdrop{
        display:block;
    }
    .title {
        font-size: 1.4rem;   
    }
    .list {
        flex-direction: column;
        height: auto;
        top:50%;
        border: 2px solid blueviolet;
        border-radius:5px;
        width:70%;
        margin:50px auto;
        box-shadow: 0 0 .5em rgb(0, 0, 0);
        background-color:#1a1a1a;
    }
    .contact {
        border:none;
        border-radius:none;
        box-shadow:none;
    }
    .contact:hover {
        box-shadow:none;
    }
    .items,
    .contact {
        width: 100%;
        text-align: center;
        margin: 0px;
        padding: 20px 0;
    }
    .items {
        border-bottom: 1px solid blueviolet;
    }
    .hambergerBtn {
        display: block;
    }
}
@media screen and (max-width: 1000px) and (min-width: 700px) {
    .title {
        display: none;
    }
}