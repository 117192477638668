.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    bottom:50%;
    right:5px;
    transform: translateY(50%);
    z-index: 1000;
}
.container a {
    display: block;
    width:80px;
    height:80px;
    min-width: none;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: blueviolet;
    margin:5px;
    transition: .3s ease-in-out;
}
.container a img {
    width:90%;
    height:90%;
}

.container a:hover {
    padding:0 20px;
    box-shadow: 0 0 7px blueviolet;
}
@media (max-width: 750px) {
    .container {
        flex-direction: row;
        bottom:0;
        transform:translateY(0)
    }
    .container a {
        width:60px;
        height:60px;
    }
}