.app {
    margin-top:50px;
    min-height:calc(100vh - 50px);
}
@media screen and (max-width: 600px) {
    .section {
        justify-content: center;
    }
    .section:nth-child(1) {
        padding:0;
    }
    .main {
        scroll-snap-type: none;
    }
}
/* width */
::-webkit-scrollbar {
    width:7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: blueviolet;
    border-radius: 10px;
}
::-webkit-scrollbar-corner {
    background: none;
}