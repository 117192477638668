
/* Card Styling */
.card {
    position: relative;
    flex-shrink: 0;
    display:inline-block;
    border:blueviolet solid 1px;
    margin: 10px;
    box-shadow: 0 0 .5px white,0 0 10px blueviolet;
    display:flex;
    background:#1a1a1a;
    cursor: pointer;
    overflow:hidden;
    flex-grow:1;
    min-width:320px;
    width:25vw;
    max-width:500px;
    margin:10px;
    display:inline-block;
    /* transition:all .3s .3s ease-in-out; */
}
.card:hover {
    border-color: hsl(271, 96%, 63%);
    box-shadow: 0 0 1.5em blueviolet;
}

/* Card internal structure styles */
.sample{
    width: clamp(300px, 25vw, 400px);
    display:inline-block;
}

.img {
    width:100%;
}

/* tech list styling */
.techList li{
    display:inline;
    background-color:blueviolet;
    border-radius:1rem;
    padding: 0 .4rem;
    color: black;
    margin:7px 5px;
}
/* Card text styling */
.textContainer {
    margin: 10px;
    /* position: relative; */
    color:white;
}
.textContainer h1 {
    font-size: 2rem;
    font-weight: bold;
    color: white;
    display:inline-block;
    margin-left:5px;
}
.textContainer h3 {
    font-size: 1.1rem;  
    font-weight: bold;
    color: blueviolet;
    margin:10px;
}
.textContainer p {
    font-size: 1rem;
    color: white;
    margin:10px;
}
.links {
    margin-top:15px;
    margin-bottom:10px;
    display:inline-block;
}
.links a {

    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;

    padding: .2rem .5rem;
    margin:5px;

    border-radius: .4rem;
    border:blueviolet solid 2px;
    color:blueviolet;
    transition: all .2s ease-in-out;
}

.links a:hover {
    font-weight: 400;
    color: hsl(271, 96%, 83%);
    text-shadow: 0 0 1em blueviolet, 0 0 2em blueviolet;
    border-color: hsl(271, 96%, 63%);
    box-shadow: 0 0 .4em blueviolet;
}
.container {
    width: 90vw;
    height: 70vh;
    padding: 15vh 5vw;
    z-index: 1000;
    overflow:scroll;
    display:flex;
    justify-content: center;
    background:rgba(0,0,0,.3);
    position:fixed;
    top:0;
    left:0;
}

.document {
    max-width: 815px;
    height:fit-content;
    padding: 50px 25px;
    background: white;
    position:relative;
    background-color: #151515;
    box-shadow:0 0 5px 0px hsl(271, 96%, 63%);
    border:2px solid blueviolet;
    z-index:1001;
}
.close {
    transform:rotate(45deg);
    border-radius: 50%;
    font-weight:bold;
    font-size:40px;
    background-color:transparent;
    border:none;
    line-height:30px;
    width:30px;
    position:absolute;
    top:20px;
    right:15px;
    z-index:1002;
}
.document * {
    max-width:100%;
    color:blueviolet;
}
.document p {
    margin-bottom:30px;
    color:white;
}
@media screen and (max-width: 650px) {
    .container {
        width: 95vw;
    }
}

@media screen and (max-width:850px) {
    .card {
        width:100%;
        min-width:none;
        margin:20px 0;
    }
    .document  {
        font-size:12px;
    }
    .document p {
        font-size:12px;
    }

}